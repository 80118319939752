<template>
  <div id="Gestion des Categories">
    <v-container class="lighten-5">
      <v-container>
        <dashboard-core-app-bar />
      </v-container>
      <v-row style="margin-top: 20px">
        <v-breadcrumbs :items="itemsbread" divider=">"></v-breadcrumbs>
        <!-- Ajoute Catégorie  -->
        <v-col cols="12" md="12">
          <v-row class="mt-3 mx-8">
            <v-dialog v-model="catdialog" persistent max-width="600px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="resetCategory()"
                  color="#19b4bf"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon class="ma-1"> mdi-plus-box </v-icon>
                  Ajoute Nouvelle Catégorie
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="headline" color="#19b4bf" primary-title>
                  <h4 style="color: #19b4bf">
                    Création d'une nouvelle catégorie
                  </h4>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-text-field
                      cols="12"
                      sm="6"
                      v-model="categoryName"
                      @change="resetImageName()"
                      label="Nom de la Catégorie"
                    ></v-text-field>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="categoryIteamLevel"
                          label="Level"
                          outlined
                          type="number"
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="categoryIteamOrder"
                          label="Order"
                          outlined
                          type="number"
                          clearable
                        ></v-text-field>
                      </v-col>

                      <v-col class="justify-center">
                        <v-text-field
                          v-model="categoryIteamLibelle"
                          label="libelle"
                          outlined
                          clearable
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-col class="justify-center">
                      <v-img
                        v-if="this.imageCategorie"
                        style="margin-left: 140px"
                        contain
                        max-height="295"
                        max-width="210"
                        :src="this.imageCategorie"
                      ></v-img>

                      <v-file-input
                        class="justify-center"
                        v-if="this.categoryName"
                        hide-input
                        prepend-icon="mdi-camera"
                        v-model="images"
                        label="Remplacer l'image"
                        @change="uploadImageNewCategories()"
                        @click:clear="callItBack()"
                      ></v-file-input>
                    </v-col>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="resetCategory()">
                    Annuler
                  </v-btn>

                  <v-dialog v-model="dialog" width="600">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ml-6"
                        color="blue darken-1"
                        text
                        v-bind="attrs"
                        v-on="on"
                        :disabled="
                          (imageCategorie && imageCategorie.trim() === '') ||
                          !imageCategorie ||
                          (imageCategorie && imageCategorie.match(/^ *$/)) ||
                          (categoryName && categoryName.trim() === '') ||
                          !categoryName ||
                          (categoryName && categoryName.match(/^ *$/)) ||
                          (categoryIteamOrder &&
                            categoryIteamOrder.trim() === '') ||
                          !categoryIteamOrder ||
                          (categoryIteamOrder &&
                            categoryIteamOrder.match(/^ *$/)) ||
                          (categoryIteamLevel &&
                            categoryIteamLevel.trim() === '') ||
                          !categoryIteamLevel ||
                          (categoryIteamLevel &&
                            categoryIteamLevel.match(/^ *$/)) ||
                          (categoryIteamLibelle &&
                            categoryIteamLibelle.trim() === '') ||
                          !categoryIteamLibelle ||
                          (categoryIteamLibelle &&
                            categoryIteamLibelle.match(/^ *$/))
                        "
                        categoryIteamLibelle
                      >
                        Confirmer
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="headline" primary-title>
                        <h4 style="color: #19b4bf">Confirmation</h4>
                      </v-card-title>
                      <v-card-text>
                        <div>
                          Êtes vous sur d'ajouter une nouvelle catégorie portant
                          le nom

                          <span class="primary--text">{{ categoryName }}</span>
                        </div>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="red lighten-2"
                          text
                          @click="dialog = false"
                        >
                          Non
                        </v-btn>
                        <v-btn
                          color="#19b4bf"
                          text
                          v-on:click.prevent="addCatégorieInFirebase()"
                          @click="dialog = false"
                        >
                          Oui
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </v-col>
        <!--Fin Ajoute Catégorie  -->
      </v-row>
      <!--  Catégorie Tree  -->
      <v-col cols="12" md="12">
        <v-card class="pa-3" outlined tile>
          <v-col cols="12" md="12">
            <v-card>
              <v-card-title> Catégories </v-card-title>
              <v-row class="pa-4" justify="space-between">
                <v-col cols="5">
                  <v-treeview
                    :load-children="loadSubCategory"
                    :items="items"
                    item-key="name"
                  >
                    <template v-slot:label="{ item }">
                      <a
                        style="font-size: 20px; font-weight: 500"
                        @click="openDetails(item)"
                        >{{ item.libelle }}</a
                      >
                    </template>
                  </v-treeview>
                </v-col>
                <!-- Fin Catégorie Tree  -->
                <v-divider vertical></v-divider>
                <v-divider vertical></v-divider>
                <!--  Catégorie et sous Catégorie Détailles   -->
                <v-col class="d-flex text-center">
                  <v-scroll-y-transition mode="out-in">
                    <v-card class="mx-auto" flat>
                      <v-card-title class="justify-center">
                        <v-img
                          contain
                          max-height="295"
                          max-width="210"
                          :src="this.iteamImage"
                        ></v-img>
                      </v-card-title>
                      <v-file-input
                        class="justify-center"
                        v-if="this.iteamImage"
                        hide-input
                        prepend-icon="mdi-camera"
                        v-model="image"
                        label="Remplacer l'image"
                        @change="onFileChange"
                        @click:clear="callItBack()"
                      ></v-file-input>

                      <h2 class="mb-6 mt-6">
                        {{ this.iteamName }}
                      </h2>
                      <v-divider></v-divider>

                      <div class="mb-2">
                        <v-col>
                          <v-row>
                            <v-col
                              cols="12"
                              sm="6"
                              v-if="!this.iteamCategoryType && this.iteamName"
                            >
                              <v-text-field
                                @change="UpdateCondition()"
                                v-model="iteamDescription"
                                label="Description"
                                outlined
                                clearable
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="6"
                              v-if="!this.iteamCategoryType && this.iteamName"
                            >
                              <v-text-field
                                @change="UpdateCondition()"
                                v-model="iteamExternalId"
                                label="ExternalId"
                                outlined
                                clearable
                              ></v-text-field>
                            </v-col>
                            <v-col v-if="this.iteamName">
                              <v-text-field
                                @change="UpdateCondition()"
                                v-model="iteamLevel"
                                label="Level"
                                outlined
                                type="number"
                                clearable
                              ></v-text-field>
                            </v-col>

                            <v-col
                              v-if="!this.iteamCategoryType && this.iteamName"
                            >
                              <v-text-field
                                @change="UpdateCondition()"
                                v-model="iteamParentId"
                                label="ParentId"
                                outlined
                                type="number"
                                clearable
                              ></v-text-field>
                            </v-col>

                            <v-col v-if="this.iteamName">
                              <v-text-field
                                @change="UpdateCondition()"
                                v-model="iteamOrder"
                                label="Order"
                                outlined
                                type="number"
                                clearable
                              ></v-text-field>
                            </v-col>
                            <v-col v-if="this.iteamName">
                              <v-text-field
                                @change="UpdateCondition()"
                                v-model="iteamLibelle"
                                label="Libelle"
                                outlined
                                clearable
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col>
                          <!-- Enregistrement -->
                          <v-btn
                            class="ml-5"
                            @click="saveImage()"
                            v-if="
                              this.iteamCategoryType &&
                              this.iteamName &&
                              this.update
                            "
                            color="#19b4bf"
                            dark
                          >
                            <v-icon class="ma-1"> mdi-content-save-all </v-icon>
                            sauvegarder les modifications
                          </v-btn>
                          <v-btn
                            @click="saveImage()"
                            v-if="
                              !this.iteamCategoryType &&
                              this.iteamName &&
                              this.update
                            "
                            color="#19b4bf"
                            dark
                          >
                            <v-icon class="ma-1"> mdi-content-save-all </v-icon>
                            sauvegarder les modifications
                          </v-btn>
                        </v-col>
                        <!-- Fin Enregistrement -->
                        <!-- Fin Catégorie et sous Catégorie Détailles   -->
                        <v-col
                          style="margin-left: 10px"
                          v-if="this.iteamCategoryType && this.iteamName"
                        >
                          <!-- Ajoute Sous Catégorie  -->
                          <v-row justify="center">
                            <v-dialog
                              v-model="subdialog"
                              persistent
                              max-width="600px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="#19b4bf"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon class="ma-1"> mdi-plus-box </v-icon>

                                  Ajoute Nouvelle Sous Catégorie
                                </v-btn>
                              </template>
                              <v-card>
                                <v-card-title class="headline" primary-title>
                                  <h4 style="color: #19b4bf">
                                    Création d'une nouvelle sous catégorie
                                  </h4>
                                </v-card-title>
                                <v-card-text>
                                  <v-container>
                                    <v-text-field
                                      cols="12"
                                      sm="6"
                                      v-model="SousCatégorieName"
                                      @change="resetImageName()"
                                      label="Nom de la sous Catégorie"
                                    ></v-text-field>

                                    <v-row>
                                      <v-col cols="12" sm="6">
                                        <v-text-field
                                          v-model="sousCatégorieIteamLevel"
                                          label="Level"
                                          outlined
                                          type="number"
                                          clearable
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="6">
                                        <v-text-field
                                          v-model="sousCatégorieIteamParentId"
                                          label="Parent Id"
                                          outlined
                                          type="number"
                                          clearable
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="6">
                                        <v-text-field
                                          class="justify-center"
                                          v-model="sousCatégorieIteamOrder"
                                          label="Order"
                                          outlined
                                          type="number"
                                          clearable
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="6">
                                        <v-text-field
                                          class="justify-center"
                                          v-model="sousCatégorieExternalId"
                                          label="External Id"
                                          outlined
                                          clearable
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="12">
                                        <v-text-field
                                          v-model="sousCatégorieLibelle"
                                          label="libelle"
                                          outlined
                                          clearable
                                        ></v-text-field>
                                      </v-col>
                                      <v-col class="justify-center">
                                        <v-text-field
                                          class="justify-center"
                                          v-model="sousCatégorieDescription"
                                          label="Description"
                                          outlined
                                          clearable
                                        ></v-text-field>
                                      </v-col>
                                    </v-row>

                                    <v-col class="justify-center">
                                      <v-img
                                        v-if="this.imagesSousCatégorie"
                                        style="margin-left: 140px"
                                        contain
                                        max-height="295"
                                        max-width="210"
                                        :src="this.imagesSousCatégorie"
                                      ></v-img>

                                      <v-file-input
                                        class="justify-center"
                                        v-if="this.SousCatégorieName"
                                        hide-input
                                        prepend-icon="mdi-camera"
                                        v-model="images"
                                        label="Remplacer l'image"
                                        @change="uploadImageNewSousCategories()"
                                        @click:clear="callItBack()"
                                      ></v-file-input>
                                    </v-col>
                                  </v-container>
                                </v-card-text>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="resetSubCategory()"
                                  >
                                    Annuler
                                  </v-btn>

                                  <v-dialog v-model="dialog" width="600">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        class="ml-6"
                                        color="blue darken-1"
                                        text
                                        v-bind="attrs"
                                        v-on="on"
                                        :disabled="
                                          (imagesSousCatégorie &&
                                            imagesSousCatégorie.trim() ===
                                              '') ||
                                          !imagesSousCatégorie ||
                                          (imagesSousCatégorie &&
                                            imagesSousCatégorie.match(
                                              /^ *$/
                                            )) ||
                                          (SousCatégorieName &&
                                            SousCatégorieName.trim() === '') ||
                                          !SousCatégorieName ||
                                          (SousCatégorieName &&
                                            SousCatégorieName.match(/^ *$/)) ||
                                          (sousCatégorieIteamLevel &&
                                            sousCatégorieIteamLevel.trim() ===
                                              '') ||
                                          !sousCatégorieIteamLevel ||
                                          (sousCatégorieIteamLevel &&
                                            sousCatégorieIteamLevel.match(
                                              /^ *$/
                                            )) ||
                                          (sousCatégorieIteamParentId &&
                                            sousCatégorieIteamParentId.trim() ===
                                              '') ||
                                          !sousCatégorieIteamParentId ||
                                          (sousCatégorieIteamParentId &&
                                            sousCatégorieIteamParentId.match(
                                              /^ *$/
                                            )) ||
                                          (sousCatégorieIteamOrder &&
                                            sousCatégorieIteamOrder.trim() ===
                                              '') ||
                                          !sousCatégorieIteamOrder ||
                                          (sousCatégorieIteamOrder &&
                                            sousCatégorieIteamOrder.match(
                                              /^ *$/
                                            )) ||
                                          (sousCatégorieExternalId &&
                                            sousCatégorieExternalId.trim() ===
                                              '') ||
                                          !sousCatégorieExternalId ||
                                          (sousCatégorieExternalId &&
                                            sousCatégorieExternalId.match(
                                              /^ *$/
                                            )) ||
                                          (sousCatégorieDescription &&
                                            sousCatégorieDescription.trim() ===
                                              '') ||
                                          !sousCatégorieDescription ||
                                          (sousCatégorieDescription &&
                                            sousCatégorieDescription.match(
                                              /^ *$/
                                            )) ||
                                          (sousCatégorieLibelle &&
                                            sousCatégorieLibelle.trim() ===
                                              '') ||
                                          !sousCatégorieLibelle ||
                                          (sousCatégorieLibelle &&
                                            sousCatégorieLibelle.match(/^ *$/))
                                        "
                                      >
                                        Confirmer
                                      </v-btn>
                                    </template>
                                    <v-card>
                                      <v-card-title
                                        class="headline"
                                        primary-title
                                      >
                                        <h4 style="color: #19b4bf">
                                          Confirmation
                                        </h4>
                                      </v-card-title>
                                      <v-card-text>
                                        <div>
                                          Êtes vous sur d'ajouter une nouvelle
                                          sous catégorie portant le nom

                                          <span class="primary--text">{{
                                            SousCatégorieName
                                          }}</span>
                                        </div>
                                      </v-card-text>

                                      <v-divider></v-divider>

                                      <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                          color="red lighten-2"
                                          text
                                          @click="dialog = false"
                                        >
                                          Non
                                        </v-btn>
                                        <v-btn
                                          color="#19b4bf"
                                          text
                                          v-on:click.prevent="
                                            addSousCatégorieInFirebase()
                                          "
                                          @click="dialog = false"
                                        >
                                          Oui
                                        </v-btn>
                                      </v-card-actions>
                                    </v-card>
                                  </v-dialog>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-row>
                        </v-col>
                        <!-- Fin Ajoute Sous Catégorie  -->

                        <!-- Suppression Catégorie et Sous Catégorie  -->
                        <v-col style="margin-left: 10px">
                          <v-btn
                            v-if="this.iteamCategoryType && this.iteamName"
                            @click="dialog_delete = true"
                            color="error"
                            dark
                          >
                            <v-icon class="ma-1">mdi-delete </v-icon>
                            Supprimer Catégorie
                          </v-btn>
                          <v-btn
                            v-if="!this.iteamCategoryType && this.iteamName"
                            @click="dialog_delete = true"
                            color="error"
                            dark
                          >
                            <v-icon class="ma-1">mdi-delete </v-icon>
                            Supprimer Sous Catégorie
                          </v-btn>

                          <v-dialog v-model="dialog_delete" width="500">
                            <v-card>
                              <v-card-title class="headline" primary-title>
                                <h4 style="color: error">Confirmation</h4>
                              </v-card-title>

                              <v-card-text>
                                Es-tu vous sûr de supprimer
                                {{ this.iteamName }} ?
                              </v-card-text>

                              <v-divider></v-divider>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="primary"
                                  text
                                  @click="deleteCategory()"
                                >
                                  Confirmer
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <!-- Fin Suppression Catégorie et Sous Catégorie  -->
                        </v-col>
                      </div>
                    </v-card>
                  </v-scroll-y-transition>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-card>
      </v-col>
    </v-container>
  </div>
</template>
<script>
import db from "../../../firebaseInit";
import { firebase } from "@firebase/app";
import FirebaseDB from "../../utils/firebaseDB";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import NodesName from "../../utils/nodesName";
import NodesFieldName from "../../utils/nodesFieldName";
export default {
  components: {
    DashboardCoreAppBar: () =>
      import("../../materialDashboard/components/core/AppBar.vue"),
    VueUploadMultipleImage,
  },

  data: () => ({
    itemsbread: [
      {
        text: "Dashboard",
        disabled: false,
        href: "/",
      },
    ],
    items: [],
    id: null,
    totalCategorysCount: null,
    iteamName: null,
    iteamID: null,
    iteamImage: null,
    iteamAdded_by: null,
    iteamCreationDate: null,
    iteamCategory: false,
    categoryName: null,
    SousCatégorieName: null,
    imagesSousCatégorie: null,
    images: null,
    options: {},
    dialog: false,
    dialog_delete: false,
    subdialog: false,
    uploaded: false,
    iteamOrder: null,
    iteamLibelle: null,
    iteamParentId: null,
    iteamLevel: null,
    iteamDescription: null,
    iteamExternalId: null,
    image: null,
    url: null,
    iteamCategoryType: false,
    userEmail: null,
    userID: null,
    imageCategorie: null,
    catdialog: false,
    update: false,
    categoryIteamLevel: null,
    categoryIteamOrder: null,
    sousCatégorieIteamOrder: null,
    sousCatégorieIteamParentId: null,
    sousCatégorieIteamLevel: null,
    sousCatégorieDescription: null,
    sousCatégorieExternalId: null,
    sousCatégorieLibelle: null,
    categoryIteamLibelle: null,
  }),

  async beforeMount() {
    this.getCategories();
    firebase.auth().onAuthStateChanged(function (user) {
      db.collection(NodesName.USERS_DETAILS)
        .where(NodesFieldName.EMAIL, "==", user.email)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            localStorage.setItem("adminID", doc.data().userId);
          });
        });
    });

    this.userID = localStorage.getItem("adminID");
  },

  methods: {
    //condition d'enregistrement
    UpdateCondition() {
      this.update = true;
    },

    // rest Image lors de modification du nom
    resetImageName() {
      this.imageCategorie = null;
      this.imagesSousCatégorie = null;
      this.images = null;
    },
    // Clic sur anuller ajoute sous categories
    resetSubCategory() {
      this.uploaded = false;
      this.imagesSousCatégorie = null;
      this.iteamName = null;
      this.iteamImage = null;
      this.SousCatégorieName = null;
      this.subdialog = false;
      this.sousCatégorieIteamOrder = null;
      this.sousCatégorieIteamLevel = null;
      this.sousCatégorieIteamParentId = null;
      this.sousCatégorieExternalId = null;
      this.sousCatégorieLibelle = null;
      this.sousCatégorieDescription = null;
    },
    // Clic sur anuller ajoute categories
    resetCategory() {
      this.iteamName = null;
      this.iteamImage = null;
      this.catdialog = false;
      this.categoryName = null;
      this.imageCategorie = null;
      this.uploaded = false;
      this.categoryIteamOrder = null;
      this.categoryIteamLevel = null;
      this.categoryIteamLibelle = null;
    },
    // update image et parametre categorie et sous categorie
    saveImage() {
      if (!this.iteamCategoryType) {
        db.collection(NodesName.PRODUCT_TO_SHARE_SUBCATEGORIES)
          .where(NodesFieldName.NAME, "==", this.iteamName)
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              doc.ref
                .update({
                  level: Number(this.iteamLevel),
                  order: Number(this.iteamOrder),
                  libelle: this.iteamLibelle,
                  parentId: Number(this.iteamParentId),
                  image: this.iteamImage,
                  updatedBy: this.userID,
                  updatedDate: new Date(),
                  description: this.iteamDescription,
                  externalId: this.iteamExternalId,
                })
                .then(() => {
                  this.items = [];
                  this.categoryName = null;
                  this.images = null;
                  this.uploaded = false;
                  this.update = false;
                  this.resetSubCategory();
                  this.getCategories();
                });
            });
          });
      }
      if (this.iteamCategoryType) {
        db.collection(NodesName.PRODUCT_TO_SHARE_CATEGORIES)
          .where(NodesFieldName.NAME, "==", this.iteamName)
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              doc.ref
                .update({
                  level: Number(this.iteamLevel),
                  order: Number(this.iteamOrder),
                  libelle: this.iteamLibelle,
                  image: this.iteamImage,
                  updatedBy: this.userID,
                  updatedDate: new Date(),
                })
                .then(() => {
                  this.items = [];
                  this.categoryName = null;
                  this.images = null;
                  this.uploaded = false;
                  this.update = false;
                  this.resetCategory();
                  this.getCategories();
                });
            });
          });
      }
    },
    // Supprimer  categories  et sous  categories
    deleteCategory() {
      if (!this.iteamCategoryType) {
        db.collection(NodesName.PRODUCT_TO_SHARE_SUBCATEGORIES)
          .where(NodesFieldName.NAME, "==", this.iteamName)
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              doc.ref
                .update({
                  deleted: true,
                  deletedBy: this.userID,
                  deletedDate: new Date(),
                })
                .then(() => {
                  this.items = [];
                  this.iteamImage = null;
                  this.iteamName = null;
                  this.iteamLevel = null;
                  this.dialog_delete = false;

                  this.getCategories();
                });
            });
          });
      }
      if (this.iteamCategoryType) {
        db.collection(NodesName.PRODUCT_TO_SHARE_CATEGORIES)
          .where(NodesFieldName.NAME, "==", this.iteamName)
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              doc.ref
                .update({
                  deleted: true,
                  deletedBy: this.userID,
                  deletedDate: new Date(),
                })
                .then(() => {
                  this.items = [];
                  this.iteamImage = null;
                  this.iteamName = null;
                  this.dialog_delete = false;
                  this.getCategories();
                });
            });
          });
      }
    },
    // clear image in iteam
    callItBack() {
      this.iteamImage = [];
    },
    //Upload Image categories et sous categories dans storage
    async onFileChange() {
      if (this.iteamCategoryType) {
        await firebase

          .storage()
          .ref(
            "Liste des categories/" +
              this.iteamName +
              "/" +
              "/" +
              this.image.name
          )
          .put(this.image)
          .then((response) => {
            firebase
              .storage()
              .ref(
                "Liste des categories/" +
                  this.iteamName +
                  "/" +
                  "/" +
                  this.image.name
              )
              .getDownloadURL()
              .then((url) => {
                this.iteamImage = url;
                this.update = true;
              })
              .catch(function (error) {
                console.log(error);
              });
          });
      }
      if (!this.iteamCategoryType) {
        await firebase

          .storage()
          .ref(
            "Liste des categories/" +
              this.iteamCategory +
              "/" +
              "/" +
              this.iteamName +
              "/" +
              "/" +
              this.image.name
          )
          .put(this.image)
          .then((response) => {
            firebase
              .storage()
              .ref(
                "Liste des categories/" +
                  this.iteamCategory +
                  "/" +
                  "/" +
                  this.iteamName +
                  "/" +
                  "/" +
                  this.image.name
              )
              .getDownloadURL()
              .then((url) => {
                this.iteamImage = url;
                this.update = true;
              })
              .catch(function (error) {
                console.log(error);
              });
          });
      }
    },
    // Avoir liste des categories
    async getCategories() {
      var query = db.collection(NodesName.PRODUCT_TO_SHARE_CATEGORIES);
      this.totalCategorysCount = await FirebaseDB.getCount(query);

      query
        .where(NodesFieldName.DELETED, "==", false)
        .orderBy(NodesFieldName.ORDER, "asc")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.items.push({
              name: doc.data().name,
              children: new Array(),
              id: doc.data().uniqueId,
              deleted: doc.data().deleted,
              createdBy: doc.data().createdBy,
              creationDate: doc.data().creationDate,
              image: doc.data().image,
              categoryType: true,
              level: doc.data().level,
              order: doc.data().order,
              libelle: doc.data().libelle,
            });
          });
        });
    },
    //Upload new Image update categories et sous categories dans storage
    async uploadImageNewCategories() {
      await firebase
        .storage()
        .ref(
          "Liste des categories /" +
            this.categoryName +
            "/" +
            "/" +
            this.images.name
        )
        .put(this.images)
        .then((response) => {
          firebase
            .storage()
            .ref(
              "Liste des categories /" +
                this.categoryName +
                "/" +
                "/" +
                this.images.name
            )
            .getDownloadURL()
            .then((url) => {
              this.imageCategorie = url;
            })
            .catch(function (error) {
              console.log(error);
            });
        });
      this.uploaded = true;
    },

    async uploadImageNewSousCategories() {
      await firebase
        .storage()
        .ref(
          "Liste des categories /" +
            this.iteamName +
            "/" +
            "/" +
            this.SousCatégorieName +
            "/" +
            "/" +
            this.images.name
        )
        .put(this.images)
        .then((response) => {
          firebase
            .storage()
            .ref(
              "Liste des categories /" +
                this.iteamName +
                "/" +
                "/" +
                this.SousCatégorieName +
                "/" +
                "/" +
                this.images.name
            )
            .getDownloadURL()
            .then((url) => {
              this.imagesSousCatégorie = url;
            })
            .catch(function (error) {
              console.log(error);
            });
        });
      this.uploaded = true;
    },

    //Ajoute Catégorie dans Firebase
    addCatégorieInFirebase() {
      var FirebaseCategoryRef = db
        .collection(NodesName.PRODUCT_TO_SHARE_CATEGORIES)
        .doc();
      db.collection(NodesName.PRODUCT_TO_SHARE_CATEGORIES)
        .where(NodesFieldName.NAME, "==", this.categoryName)
        .where(NodesFieldName.DELETED, '==', false)
        .limit(1)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.size == 0) {
            FirebaseCategoryRef.set({
              name: this.categoryName,
              image: this.imageCategorie,
              creationDate: new Date(),
              deleted: false,
              uniqueId: FirebaseCategoryRef.id,
              createdBy: this.userID,
              level: Number(this.categoryIteamLevel),
              order: Number(this.categoryIteamOrder),
              libelle: this.categoryIteamLibelle,
              numberOfViews: 0,
            })
              .then(() => {
                this.items = [];
                this.getCategories();
                this.categoryName = null;
                this.imageCategorie = null;
                this.catdialog = false;
                this.uploaded = false;
                this.categoryIteamOrder = null;
                this.categoryIteamLevel = null;
                this.categoryIteamLibelle = null;
                this.resetCategory();
              })
              .catch(function (error) {
                console.error("Error adding document: ", error);
              });
          } else {
            confirm("la catégorie existe déjà ");
          }
        });
    },
    //Ajoute sous Catégorie dans Firebase
    addSousCatégorieInFirebase() {
      var FirebaseSubcategoryRef = db
        .collection(NodesName.PRODUCT_TO_SHARE_SUBCATEGORIES)
        .doc();
      db.collection(NodesName.PRODUCT_TO_SHARE_SUBCATEGORIES)
        .where(NodesFieldName.NAME, "==", this.SousCatégorieName)
        .where(NodesFieldName.DELETED, "==", false)
        .where(NodesFieldName.CATEGORY, "==", this.iteamName)
        .limit(1)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.size == 0) {
            FirebaseSubcategoryRef.set({
              name: this.SousCatégorieName,
              image: this.imagesSousCatégorie,
              level: Number(this.sousCatégorieIteamLevel),
              order: Number(this.sousCatégorieIteamOrder),
              parentId: Number(this.sousCatégorieIteamParentId),
              category: this.iteamID,
              creationDate: new Date(),
              deleted: false,
              uniqueId: FirebaseSubcategoryRef.id,
              createdBy: this.userID,
              externalId: this.sousCatégorieExternalId,
              libelle: this.sousCatégorieLibelle,
              description: this.sousCatégorieDescription,
              numberOfViews: 0,
            })
              .then(() => {
                this.items = [];
                this.getCategories();
                this.Name = null;
                this.images = null;
                this.uploaded = false;
                this.SousCatégorieName = null;
                this.imagesSousCatégorie = null;
                this.iteamLevel = null;
                this.iteamParentId = null;
                this.iteamOrder = null;
                this.iteamLibelle = null;
                this.subdialog = false;
                this.resetSubCategory();
              })
              .catch(function (error) {
                console.error("Error adding document: ", error);
              });
          } else {
            confirm("la sous catégorie existe déjà ");
          }
        });
    },
    //Ovrire detailles Catégorie et sous Catégorie
    openDetails(item) {
      this.iteamImage = [];
      this.update = false;
      this.iteamName = item.name;
      this.iteamID = item.id;
      this.iteamImage = item.image;
      this.iteamAdded_by = item.createdBy;
      this.iteamCreationDate = item.creationDate;
      this.iteamCategory = item.category;
      this.iteamLevel = item.level;
      this.iteamParentId = item.parentId;
      this.iteamOrder = item.order;
      this.iteamLibelle = item.libelle;
      this.iteamCategoryType = item.categoryType;
      this.iteamDescription = item.description;
      this.iteamExternalId = item.externalId;
    },
    // Avoir liste des sous categories
    async loadSubCategory(item) {
      db.collection(NodesName.PRODUCT_TO_SHARE_SUBCATEGORIES)
        .where(NodesFieldName.DELETED, "==", false)
        .where(NodesFieldName.CATEGORY, "==", item.id)
        .orderBy(NodesFieldName.ORDER, "asc")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            item.children.push({
              name: doc.data().name,
              createdBy: doc.data().createdBy,
              category: doc.data().category,
              creationDate: doc.data().creationDate,
              level: doc.data().level,
              name: doc.data().name,
              parentId: doc.data().parentId,
              id: doc.data().uniqueId,
              order: doc.data().order,
              libelle: doc.data().libelle,
              image: doc.data().image,
              categoryType: false,
              description: doc.data().description,
              externalId: doc.data().externalId,
            });
          });
        });
    },
  },
};
</script>
